<template>
    <q-drawer v-model="proxy" :width="withDrawer">
        <q-toolbar class=" tw-text-white tw-flex tw-flex-col tw-justify-center tw-w-full tw-z-10 lg:tw-hidden tw-py-2 !tw-px-0">
                <NuxtLink to="/" >
                        <ApplicationLogo  />
                </NuxtLink>
                
                <div class="tw-w-full tw-h-full tw-pt-6 tw-flex tw-justify-start tw-border-t tw-border-gray-200 tw-mt-4 tw-pl-4">
                    <ProfileMenu ></ProfileMenu>
                </div>
        </q-toolbar>
        <div class="tw-pt-4 lg:tw-pt-0">
            <slot />
        </div>
        <q-separator class="lg:tw-hidden tw-bg-gray-200"></q-separator>
        <div class="lg:tw-hidden tw-py-7 tw-px-5 tw-text-sm tw-font-normal tw-text-center tw-text-gray-400 tw-flex tw-flex-col tw-gap-6">
            
            <NuxtLink v-for="m in navbar_menu" to="{{$t(m.route_name)}}">{{ $t(m.name) }}</NuxtLink>
        </div>
    </q-drawer>
</template>

<script setup lang="ts">

import ApplicationLogo from "@/components/ApplicationLogo.vue";
import {getAppName} from "../common/helpers";
import {computed} from "vue";
import {useNavbarMenu} from '../composables/LefMenu';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const props = defineProps<{
    modelValue: boolean
}>()

const navbar_menu = useNavbarMenu()

const emits = defineEmits<{
    (e: 'update:modelValue', val: boolean): void
}>()

const appName = getAppName()

const proxy = computed({
    get: () => props.modelValue,
    set: val => emits('update:modelValue', val)
})

const withDrawer = computed(()=> $q.screen.lg ? "350" : "360")

</script>
